import { i18n } from '@/i18n';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { GenericModel } from '@/shared/model/generic-model';

const themes = [
  {
    id: 'itex-theme',
    hex: '#2f54eb',
  },
].map((theme) => ({
  ...theme,
  label: i18n(`settings.colors.${theme.id}`),
}));

function label(name) {
  return i18n(`settings.fields.${name}`);
}

const fields = {
  theme: new EnumeratorField(
    'theme',
    label('theme'),
    themes,
    { required: true },
  ),
};

export class SettingsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
